import { render, staticRenderFns } from "./v27gCDJx0G6r.vue?vue&type=template&id=eb52a964&scoped=true&"
import script from "./v27gCDJx0G6r.vue?vue&type=script&lang=js&"
export * from "./v27gCDJx0G6r.vue?vue&type=script&lang=js&"
import style0 from "./v27gCDJx0G6r.vue?vue&type=style&index=0&id=eb52a964&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb52a964",
  null
  
)

export default component.exports