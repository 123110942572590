import Vue from "vue";
import App from "./App.vue";
import store from "./store/store";
import router from "./router";

import VueCurrencyFilter from "vue-currency-filter";

import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import VueMeta from "vue-meta";
import VueScrollTo from "vue-scrollto";
import VueDragscroll from "vue-dragscroll";

import "./plugins/bootstrap-vue";
import "./assets/css/default.scss";
import "./registerServiceWorker";
import * as Resize from "iframe-resizer";
import IdleVue from "idle-vue";
import './registerServiceWorker'
import axios from "axios";

Vue.use(VueMeta);
Vue.use(Resize);

axios.get("/assets/config.json").then((response) => {
  var compKey = response.data.compKey;
  var idleTime = response.data.idleTime;

  Vue.mixin({
    data: function () {
      return {
        storeKey: compKey
      };
    },
  });

  Vue.use(VueCurrencyFilter, {
    symbol: "$",
    thousandsSeparator: ".",
    fractionCount: 2,
    fractionSeparator: ",",
    symbolPosition: "back",
    symbolSpacing: true,
  });

  const eventsHub = new Vue();
  Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: idleTime,
    startAtIdle: false,
  });

});

Vue.use(VueScrollTo);
Vue.use(VueDragscroll);

Vue.config.productionTip = false;

Vue.prototype.$firstRun16 = true;
Vue.prototype.$firstRun18 = true;

const requireComponent = require.context(
  "./components",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );
  Vue.component(componentName, componentConfig.default || componentConfig);
});

new Vue({
  router,
  store,
  created() {
    const compString = localStorage.getItem("company");
    const userString = localStorage.getItem("user");
    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit("SET_USER_DATA", userData);
    }
    if (compString) {
      const compData = JSON.parse(compString);
      this.$store.commit("SET_STORE", compData);
    }
  },
  render: (h) => h(App),
}).$mount("#app");
