<template>
  <div>
    <h2>{{ "Bestellung" | translate }}</h2>
    <div class="row">
      <div class="col-lg-12" v-for="item in order" :key="item.orderId">
        <div class="panel p-2">
          <div class="panel t1 p-2 mb-1">
            {{ "Bestell Summe" | translate }}:
            <strong>{{ posTotal.toFixed(2) | currency(cSymb) }}</strong>
            <br />
            {{ "Kunde" | translate }}:
            <strong>{{ item.clientName }}</strong>
            <br />
            {{ "Shift" | translate }}:
            <strong>{{ item.shift }}</strong>
            <br />
            {{ "Liefer Datum" | translate }}:
            <strong>{{ time(item.preOrderDate) }}</strong>
            <br />
            {{ "Anmerkung" | translate }}:
            <strong>{{ item.comment }}</strong>
            <br />
            {{ "Gesamtbetrag" | translate }}:
            <strong>{{ item.amount | currency(cSymb) }}</strong>
            <br />
            {{ "Bestell Datum" | translate }}:
            <strong>{{ time(item.date) }}</strong>
          </div>

          <div class="panel t2 p-2">
            {{ "Positionen" | translate }}:
            <br />
            <div class="pl-2" v-for="(pos, index) in positions" :key="index">
              <strong>{{ pos.count }} x {{ pos.name }}</strong>
              (@{{ pos.singlePrice | currency(cSymb) }})
              <span v-if="pos.note">{{ "***" + pos.note + "***" }}</span>
              <br />
              <i>
                {{ "Total" | translate }} =
                {{ (pos.count * pos.singlePrice) | currency(cSymb) }}
              </i>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <button
            class="btn btn-lg btn-block btn-primary m-0"
            type="button"
            @click="$router.go(-1)"
          >
            {{ "Zurück" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DataService from "@/services/DataService";

export default {
  name: "app-poslist",
  props: {
    orderId: {
      type: String,
    },
  },
  components: {},
  data() {
    return {
      order: [],
      uAddress: [],
      positions: [],
      posTotal: 0,
      contentRendered: false,
      progress: 0,
      generatingPdf: false,
      pdfDownloaded: false,
    };
  },
  created: function () {
    DataService.getOrders(this.userData.apiKey, this.userData.token).then(
      (response) => {
        this.order = response.data.filter(
          (item) => item.orderId == this.orderId
        );
        let result = Object.values(
          this.order[0].pos.reduce((r, e) => {
            let k = `${e.articleId}|${e.singlePrice}`;
            if (!r[k]) r[k] = { ...e, count: e.quantity };
            else r[k].count += 1;
            return r;
          }, {})
        );
        this.positions = result;
        for (var i = 0; i < this.order[0].pos.length; i++) {
          this.posTotal +=
            this.order[0].pos[i].quantity * this.order[0].pos[i].singlePrice;
        }
        DataService.getUser(this.userData.apiKey, this.userData.token).then(
          (response) => {
            this.uAddress = response.data.addresses.filter(
              (item) => item.id == this.order[0].addressId
            );
          }
        );
      }
    );
  },

  computed: {
    ...mapGetters(["userData", "companyData"]),
    cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
  },
  methods: {
    goBack() {
      setTimeout(() => {
        this.$router.go(-1);
      }, 300);
    },
    time(value) {
      var a = new Date(value);
      var year = a.getFullYear();
      var month = a.getMonth() + 1;
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      if (min.toString().length == 1) {
        min = "0" + min;
      }
      var time = date + "/" + month + "/" + year + " " + hour + ":" + min;

      return time;
    },
    payment(value) {
      if (value == 1) {
        return "Kredit Karte";
      }
      if (value == 2) {
        return "Sonstige Karte";
      }
      if (value == 3) {
        return "Paypal";
      }
      if (value == 4) {
        return "Bar bei Abholung/Lieferung";
      }
      if (value == 5) {
        return "An der Kasse";
      }
    },
    goToAdress(itemId) {
      this.$router.push({ name: "userAddress", params: { addressId: itemId } });
    },
  },
};
</script>

<style lang="scss" scoped>
.deliveryAddress {
  text-decoration: underline;
  cursor: pointer;
}
.generate-btn:disabled {
  opacity: 0.5;
}
.progress-container {
  width: 100%;
  margin-top: 25px;

  .progress-bar {
    width: 100%;
    height: 10px;
    border: 1px solid #585858;
    border-radius: 5px;
    margin-bottom: 5px;

    .progress {
      width: 0%;
      height: 100%;
      transition: 0.3s;
      background: #2c3e50;
    }
  }

  .generating-label {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
</style>

