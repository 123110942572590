<template>
  <div class="panel t1 text-center p-2">
    <h4 class="mb-0">
      {{ "Gesamtsumme" | translate }}:
      <br />
      <span
        ><strong>{{ total | currency(cSymb) }}</strong></span
      >
    </h4>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "app-cartsummary",
  computed: {
    ...mapGetters(["itemsQuantity", "subtotal", "total", "companyData"]),
    cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
  },
};
</script>

<style scoped></style>
