<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="item-image">
          <img
            class="w-100 rounded"
            v-bind:src="cartItem.imageUrl"
            @error="replaceByDefault"
          />
        </div>
        <div class="item-info">
          <div class="p-0 mt-1">
            <em
              ><b>{{ cartItem.productQuantity }}</b></em
            >
            x
            <span v-if="langIndex == 1 && cartItem.name1">{{
              cartItem.name1
            }}</span>
            <span v-else-if="langIndex == 2 && cartItem.name2">{{
              cartItem.name2
            }}</span>
            <span v-else-if="langIndex == 3 && cartItem.name3">{{
              cartItem.name3
            }}</span>
            <span v-else>{{ cartItem.name }}</span>
            <span class="item-sides price"
              >(@{{ cartItem.price | currency(cSymb) }})</span
            >
          </div>
          <div v-for="sub in cartItem.selectedOptions" :key="sub.id">
            <div v-for="subItem in sub.options" :key="subItem.id">
              <div class="item-sides p-0">
                incl. {{ cartItem.productQuantity }} x
                <span v-if="langIndex == 1 && subItem.name1">{{
                  subItem.name1
                }}</span>
                <span v-else-if="langIndex == 2 && subItem.name2">{{
                  subItem.name2
                }}</span>
                <span v-else-if="langIndex == 3 && subItem.name3">{{
                  subItem.name3
                }}</span>
                <span v-else>{{ subItem.name }}</span>
                <span class="price"
                  >(@{{ subItem.addPrice | currency(cSymb) }})</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div v-if="cartItem.productNote" class="item-note">
          <BaseIcon name="paperclip" width="16" height="16" class="mt-0" />
          <i>{{ cartItem.productNote }}</i>
        </div>
        <div class="item-course text-right">
          <span>
            <i
              ><strong>{{
                datify(cartItem.productDate, cartItem.productShift)
              }}</strong></i
            ><br />
          </span>
        </div>
        <div class="item-course text-right" v-if="cartItem.productCourse">
          <span>
            <i
              ><strong>{{ cartItem.productCourse }}</strong></i
            >
          </span>
        </div>
        <div class="row mx-1 d-flex justify-content-between my-1">
          <div class="m-0">
            <div class="item-total p-0">
              Total:
              <span
                ><strong>{{
                  cartItem.totalPrice | currency(cSymb)
                }}</strong></span
              >
            </div>
          </div>
          <div class="m-0 p-0" style="cursor: pointer">
            <span
              class="option-button small p-0 ml-1"
              :ref="'minusBtn_' + cartItem.nr"
              v-on:click="updateItem(-1)"
            >
              <BaseIcon width="20" height="20" name="minus" class="m-auto" />
            </span>
            <span
              class="option-button small p-0 ml-1"
              :ref="'plusBtn_' + cartItem.nr"
              v-on:click="updateItem(1)"
            >
              <BaseIcon width="20" height="20" name="plus" class="m-auto" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-1" />
  </div>
</template>

<script>
import DataService from "@/services/DataService";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "app-cartitem",
  props: {
    cartItem: Object,
  },
  computed: {
    ...mapGetters(["cartProducts", "companyData", "clientData"]),
    newOptions() {
      var old_options = this.cartItem.selectedOptions;
      var options = [];
      for (var i = 0; i < old_options.length; i++) {
        options.push({
          id: old_options[i].id,
          name: old_options[i].name,
          nameTranslated: old_options[i].nameTranslated,
          multiselect: old_options[i].multiselect,
          options: [],
        });
        for (var s = 0; s < old_options[i].options.length; s++) {
          if (old_options[i].options[s].optionQuantity > 0) {
            options[i].options.push({
              id: old_options[i].options[s].id,
              name: old_options[i].options[s].name,
              name1: old_options[i].options[s].name1,
              name2: old_options[i].options[s].name2,
              name3: old_options[i].options[s].name3,
              addPrice: old_options[i].options[s].addPrice,
              optionQuantity: old_options[i].options[s].optionQuantity,
            });
          }
        }
      }
      return options;
    },
    newCartItem() {
      var cT = new Object();
      cT["nr"] = this.cartItem.nr;
      cT["id"] = this.cartItem.id;
      cT["selectedOptions"] = this.newOptions;
      cT["productQuantity"] = this.cartItem.productQuantity;
      cT["totalPrice"] = this.cartItem.totalPrice;
      cT["productNote"] = this.cartItem.productNote;
      cT["productGroup"] = this.cartItem.productGroup;
      cT["productCourse"] = this.cartItem.productCourse;
      cT["productShift"] = this.cartItem.productShift;
      cT["productDate"] = this.cartItem.productDate;
      return cT;
    },
    basePrice() {
      return (this.cartItem.totalPrice / this.cartItem.productQuantity).toFixed(
        2
      );
    },
    cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
    langIndex() {
      var lang = Vue.i18n.locale();
      var index = this.companyData.languages.findIndex((x) => x === lang);
      return index;
    },
  },
  methods: {
    ...mapActions(["removeFromCart", "replaceInCart", "addToCart"]),
    replaceByDefault(e) {
      e.target.src = "/assets/img/no_image.png";
    },
    datify(dat, shift) {
      var shifts = this.companyData.shiftNames;
      var men = shifts[shift - 1];
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      var date = new Date(dat).toLocaleDateString("de-DE", options);
      if (shift == 0) {
        return "Dauer Artikel";
      } else {
        return men + " - " + date;
      }
    },
    GetArticleImageUrl(id) {
      return DataService.getArticleImageUrl(id);
    },
    updateItem(op) {
      var quantity = this.cartItem.productQuantity + op;
      if (quantity > 0 && quantity <= 10) {
        var total = quantity * this.basePrice;
        this.newCartItem["productQuantity"] = quantity;
        this.newCartItem["totalPrice"] = total;
        for (var i = 0; i < this.newOptions.length; i++) {
          for (var s = 0; s < this.newOptions[i].options.length; s++) {
            this.newOptions[i].options[s]["optionQuantity"] = quantity;
          }
        }
        this.replaceInCart(this.newCartItem);
      } else if (quantity == 0) {
        this.removeFromCart(this.cartItem);
      }
    },
  },
};
</script>

<style scoped>
.price {
  white-space: nowrap;
}
</style>
