const state = {
  client: null,
};

const mutations = {
  SET_CLIENT_DATA(state, clientData) {
    state.client = clientData;
    localStorage.setItem("client", JSON.stringify(clientData));
  },

  CLEAR_CLIENT_DATA(state) {
    state.client = 0;
    localStorage.setItem("client", 0);
  },
};

export default {
  state,
  mutations,
};
