<template>
  <div id="app">
    <div class="content">
      <NavBar />
      <div v-if="!$route.meta.hideClientHeader" class="row clientHeader">
        <div class="col-3 m-0 p-0">
          <button
            v-if="!$route.meta.hideClientButton"
            class="btn btn-sm btn-primary btn-block mx-0"
            @click="showModal()"
          >
            {{ "Auswahl" | translate }}
          </button>
        </div>
        <div class="col-9 m-0 p-0 text-right">
          <div v-if="client.name" class="name">
            {{ client.name }} 
            <BaseIcon
              name="home"
              width="16"
              height="16"
              class="inline"
            />
            {{ client.room }}<span v-if="client.subRoom">/{{ client.subRoom }}</span>
          </div>
          <div v-if="client.info" class="info">
            <i class="m-0">Info: {{ client.info }}</i>
          </div>
        </div>
      </div>
      <div class="container mt-2">
        <transition name="slide-fade" mode="out-in">
          <router-view :key="$route.fullPath" />
        </transition>
      </div>
      <a
        v-if="showScrollButton"
        href="#"
        id="return-top"
        v-scroll-to="{ el: '#app', offset: 80 }"
      >
        <BaseIcon name="arrow-up-circle" width="40" height="40" />
      </a>
      <Footer />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import axios from "axios";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  name: "main-app",
  components: {
    NavBar,
    Footer,
  },
  data() {
    return {
      scrollHeight: 0,
      interval: null,
      showScrollButton: false,
      showClientBar: false,
      color: "",
      btnBGGcolor: "",
      btnTXXcolor: "",
      test: "#000000",
      client: { id: null, name: null, room: null, info: null },
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    if (this.clientData) {
      this.client = this.clientData.clientID;
    }

    if (this.status) {
      this.companyData.languages.forEach(function (index) {
        if (index != "") {
          axios
            .get("/locale/" + index + ".json?v=" + Date.now() + "")
            .then((response) => {
              Vue.i18n.add(index, response.data);
            });
        }
      });
      if (!Vue.i18n.locale()) {
        Vue.i18n.set(this.companyData.defaultLanguage);
      }
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.top.scrollY > 400) {
        this.showScrollButton = true;
      } else {
        this.showScrollButton = false;
      }
    },
    showModal() {
      this.$root.$emit("showCModal");
    },
  },
  computed: {
    ...mapGetters(["companyData", "clientData"]),
    status() {
      return !!this.companyData.shopBackgroundColorRgb;
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    siteName() {
      return this.companyData.companyName;
    },
    siteDescription() {
      return this.companyData.shopDescription;
    },
  },
  watch: {
    clientData(val) {
      this.client = val.clientID;
    },
    isIdle(status) {
      if (status == true && this.$route.path != "/login") {
        window.localStorage.clear();
        window.location.href = "/login";
      }
    },
  },
};
</script>
