export const cartProducts = (state) => {
  return state.products.added.map(
    ({ nr, id, totalPrice, selectedOptions, productQuantity, productNote, productGroup, productCourse, productShift, productDate }) => {
      const cartItem = state.products.all.find((product) => product.id === id);
      return {
        ...cartItem,
        nr,
        totalPrice,
        selectedOptions,
        productQuantity,
        productNote,
        productGroup,
        productCourse,
        productShift,
        productDate
      };
    }
  );
};

export const productTotal = (state) => {
  return state.products.productTotal;
};

export const itemsQuantity = (state) => {
  return cartProducts(state).reduce((productQuantity, item) => {
    return productQuantity + item.productQuantity;
  }, 0);
};

export const total = (state) => {
  const sum = cartProducts(state).reduce((subtotal, item) => {
    return subtotal + item.totalPrice;
  }, 0);
  return sum;
};

export const loggedIn = (state) => {
  return !!state.users.user;
};

export const userData = (state) => {
  return state.users.user;
};

export const clientData = (state) => {
  return state.client.client;
};

export const orderData = (state) => {
  return state.order.order;
};

export const companyData = (state) => {
  return state.companies.company;
};