<template>
  <div>
    <div class="row" v-if="clientActive">
      <div class="col-xl-8 px-3 m-0">
        <h5>
          {{ header }}
        </h5>

        <div v-if="subGroups" class="navPill_wrapper text-center mb-3">
          <div v-dragscroll class="navPill_container">
            <div
              class="navPill t1 text-center mb-1"
              :class="item === groupName ? 'active' : ''"
              v-for="item in itemGroups"
              :key="item"
              :groupName="groupName"
              @click="updateProducts(item)"
            >
              {{ productGroupName(item) | translate }}
            </div>
            <div
              class="navPill t1 text-center mb-1"
              :class="groupName === 'all' ? 'active' : ''"
              @click="updateProducts('all')"
            >
              {{ "Alles" | translate }}
            </div>
          </div>
        </div>
        <transition name="slide-fade" mode="out-in">
          <productGroup :group="productGroup()" :key="componentKey" />
        </transition>
      </div>
      <div class="col-xl-4 px-1 d-none d-xl-block">
        <BaseIcon
          name="shopping-cart"
          width="25"
          height="25"
          class="my-1 mr-2"
          style="float: right"
        />
        <h3 class="text-right">{{ "Warenkorb" | translate }}</h3>
        <ShoppingCart />
      </div>
    </div>
  </div>
</template>

<script>
import productGroup from "@/components/productGroup.vue";
import ShoppingCart from "@/components/shopping-cart/ShoppingCart";
import { mapState, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "app-orderview",
  components: {
    productGroup,
    ShoppingCart,
  },
  data() {
    return {
      componentKey: 0,
      groupName: "",
      subGroups: true,
      scroll: true,
    };
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  created() {
    this.groupName = this.itemGroups[0];
  },

  computed: {
    ...mapState({
      products: (state) => state.products.all,
    }),
    ...mapGetters(["companyData", "clientData"]),
    clientActive() {
      if (this.clientData != null) {
        return true;
      } else {
        return false;
      }
    },
    itemGroups() {
      if(this.clientData){
        var dte = this.clientData.validFor + "T00:00:00";
        var pdts = this.products;
        var prodCopy1 = pdts.map((a) => a);
        var prodCopy2 = prodCopy1.filter(
          (item) => item.validForShift == 0 || item.validFor === dte
        );
        var spdts = prodCopy2.sort((a, b) => {
          return a.groupSort - b.groupSort;
        });
        return (pdts = [...new Set(spdts.map(({ groupName }) => groupName))]);
      }else{
        return 0 ;
      }
    },
    header() {
      if (this.clientData) {
        var shift = this.clientData.validForShift;
        var options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        var date = new Date(this.clientData.validFor).toLocaleDateString(
          "de-DE",
          options
        );
        if (shift == 0) {
          return "Dauer Artikel";
        } else {
          var shifts = this.companyData.shiftNames;
          return date + " - " + shifts[shift - 1];
        }
      } else {
        return "";
      }
    },
  },
  methods: {
    productGroup() {
      if (this.clientData) {
        var sft = this.clientData.validForShift;
        var dte = this.clientData.validFor + "T00:00:00";
        if (this.groupName === "all") {
          return this.products.filter(
            (item) =>
              item.validForShift == 0 ||
              (item.validFor === dte && item.validForShift === sft)
          );
        } else {
          var res = this.products.filter(
            (item) =>
              item.validForShift == 0 ||
              (item.validFor === dte && item.validForShift === sft)
          );
          return res.filter((item) => item.groupName === this.groupName);
        }
      }
    },
    productGroupName(m) {
      var lang = Vue.i18n.locale();
      var index = this.companyData.languages.findIndex((x) => x === lang);
      var gn = this.products.find((item) => item.groupName === m);
      if (index == 1 && gn.groupName1) {
        return gn.groupName1;
      } else if (index == 2 && gn.groupName2) {
        return gn.groupName2;
      } else if (index == 3 && gn.groupName3) {
        return gn.groupName3;
      } else {
        return m;
      }
    },
    updateProducts(val) {
      this.groupName = val;
      this.componentKey += 1;
    },
  },
  watch: {
    clientData(val) {
      if (val.validForShift != null) {
        this.groupName = this.itemGroups[0];
        this.$forceUpdate();
        this.componentKey += 1;
      }
    },
  },
};
</script>

<style scoped>
.grtxt {
  padding-left: 5px;
  width: 152px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
