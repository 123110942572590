const state = {
  user: null,
};

const mutations = {
  SET_USER_DATA(state, userData) {
    state.user = userData;
    localStorage.setItem("user", JSON.stringify(userData));
  },

  CLEAR_USER_DATA(state) {
    state.user = 0;
    localStorage.setItem("user", 0);
  },
};

export default {
  state,
  mutations,
};
