import DataService from "@/services/DataService.js";

export const getProducts = ({ commit }, credentials) => {
  DataService.getAllProducts(credentials.key).then((response) => {
    commit("RECEIVE_PRODUCTS", response.data);
  });
};

export const addToCart = ({ commit }, cartItem) => {
  commit("ADD_TO_CART", cartItem);
};

export const replaceInCart = ({ commit }, cartItem) => {
  commit("REPLACE_IN_CART", cartItem);
};

export const removeFromCart = ({ commit }, cartItem) => {
  commit("REMOVE_FROM_CART", cartItem);
};

export const setOrder = ({ commit }, orderData) => {
  commit("SET_ORDER", orderData);
};

export const clearOrder = ({ commit }) => {
  commit("CLEAR_ORDER");
};

export const removeAllFromCart = ({ commit }) => {
  commit("REMOVE_ALL_FROM_CART");
};

export const login = ({ commit }, credentials) => {
  return DataService.userLogin(credentials.apikey, credentials.loginData).then((response) => {
    commit("SET_USER_DATA", response.data);
  });
};

export const setClient = ({ commit }, selection) => {
  commit("SET_CLIENT_DATA", selection);
};

export const logout = ({ commit }) => {
  commit("CLEAR_USER_DATA");
};

export const clearStore = ({ commit }) => {
  commit("CLEAR_STORE");
};

export const setStore = ({ commit }, credentials) => {
  return DataService.getStore(credentials.apikey, credentials.token).then((response) => {
    commit("SET_STORE", response.data);
  });
};
