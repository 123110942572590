<template>
  <div>
    <div
      v-for="product in selectedProduct"
      :key="product.groupName"
      @click="$router.go(-1)"
    >
      <h3 v-if="translate == 1 && product.groupName1">
        {{ product.groupName1 | translate }}
      </h3>
      <h3 v-else-if="translate == 2 && product.groupName2">
        {{ product.groupName2 | translate }}
      </h3>
      <h3 v-else-if="translate == 3 && product.groupName3">
        {{ product.groupName3 | translate }}
      </h3>
      <h3 v-else>{{ product.groupName | translate }}</h3>
    </div>
    <div class="row">
      <div
        class="col-lg-12 p-1"
        v-for="(product, index) in selectedProduct"
        :key="index"
      >
        <singleProduct :product="product" />
      </div>
    </div>
  </div>
</template>

<script>
import singleProduct from "@/components/singleProduct";
import { mapActions, mapState, mapGetters } from "vuex";
import Vue from "vue";
export default {
  name: "app-productpage",
  components: {
    singleProduct,
  },
  created() {
    this.getProducts({ key: this.companyData.menuIdPreOrder });
  },
  props: {
    productGroupId: Number,
    productId: Number,
    productShiftId: Number,
    productValidFor: Text
  },
  methods: {
    ...mapActions(["getProducts"]),
  },
  computed: {
    ...mapState({
      products: (state) => state.products.all,
    }),
    ...mapGetters(["companyData","userData"]),
    translate() {
      var lang = Vue.i18n.locale();
      var index = this.companyData.languages.findIndex((x) => x === lang);
      return index;
    },
    selectedProduct() {
      return this.products.filter(
        (item) =>
          item.id == this.productId && item.groupId == this.productGroupId && item.validForShift == this.productShiftId && item.validFor == this.productValidFor
      );
    },
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
};
</script>

<style scoped>
h1 {
  cursor: pointer;
}
</style>
