<template>
  <div class="header_button">
    <BaseIcon
      name="shopping-cart"
      width="20"
      height="20"
      class="header_icon mr-1"
    />
    <div class="itemsQuantity">({{ itemsQuantity }})</div>
    <div class="itemsTotal">{{ total | currency(cSymb) }}</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "cart-widget",
  computed: {
    ...mapGetters(["itemsQuantity", "total", "companyData"]),
    cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
  },
};
</script>
<style scoped></style>
