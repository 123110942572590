<template>
  <div>
    <h2>{{ "Anmeldung" | translate }}</h2>
    <div class="row">
      <div class="col-lg-12">
        <div class="alert alert-danger" v-if="error">
          {{ error | translate }}
        </div>
        <b-form @submit.prevent="login">
          <div class="panel p-2 mb-4">
            <h5>{{ "Email" | translate }}:</h5>
            <b-form-input
              v-model="email"
              type="email"
              name="email"
              autocomplete="current-email"
              value
            ></b-form-input>
            <h5>{{ "Passwort" | translate }}:</h5>
            <b-form-input
              v-model="password"
              type="password"
              name="password"
              autocomplete="current-password"
              value
            ></b-form-input>
            <div class="text-right">
              <button
                class="btn btn-lg btn-primary my-3"
                type="submit"
                name="button"
              >
                Login
              </button>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "app-login",
  data() {
    return {
      email: "",
      password: "",
      error: null,
    };
  },
  computed: {
    ...mapGetters(["total", "companyData", "userData"]),
  },
  methods: {
    login() {
      var payload = {
        apikey: this.storeKey,
        loginData: {
          LoginName: this.email,
          Password: this.password,
        },
      };
      this.$store
        .dispatch("login", payload)
        .then(() => {
          var compload = {
            apikey: this.userData.apiKey,
            token: this.userData.token,
          };
          this.$store.dispatch("setStore", compload).then(() => {
          this.$router.push({ name: "order", query: { lang: 'de' } });
        })
        })
        .catch(() => {
          this.error = "Anmeldung fehlgeschlagen!";
        });
    },
  }
};
</script>

<style scoped>
h5 {
  margin-top: 5px;
}
</style>
