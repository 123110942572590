<template>
  <div>
    <BaseIcon name="shopping-cart" width="25" height="25" class="my-1 mr-2" />
    <h3>{{ "Warenkorb" | translate }}</h3>
    <div class="row">
      <div class="col-lg-12 px-1 py-1">
        <ShoppingCart />
      </div>
    </div>
  </div>
</template>

<script>
import ShoppingCart from "@/components/shopping-cart/ShoppingCart";
import { mapActions } from "vuex";

export default {
  name: "app-cartpage",
  components: {
    ShoppingCart,
  },
  props: {
    groupName: {
      type: String,
    },
    productList: {
      type: Array,
    },
  },
  methods: {
    ...mapActions({
      removeAllFromCart: "removeAllFromCart",
    }),
  },
  watch: {},
  computed: {
    productGroup() {
      return this.productList;
    },
  },
};
</script>
