import Vue from "vue";
import Vuex from "vuex";
import vuexI18n from "vuex-i18n";

import * as actions from "@/store/modules/actions";
import * as getters from "@/store/modules/getters";
import products from "@/store/modules/products";
import users from "@/store/modules/users";
import client from "@/store/modules/client";
import companies from "@/store/modules/companies";
import order from "@/store/modules/order";
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

const store = new Vuex.Store();
const strict = false;
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

Vue.use(vuexI18n.plugin, store);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions,
  getters,
  modules: {
    products,
    users,
    client,
    companies,
    order,
  },
  plugins: [vuexLocal.plugin],
  strict,
});
