<template>
  <b-navbar type="dark">
    <b-navbar-brand class="navbar-brand mx-auto" to="/">
      {{ logoUrl }}
    </b-navbar-brand>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <span v-for="(item, index) in companyData.languages" :key="index">
            <img
              v-if="item != '' && item != get_lang()"
              :src="'https://selfapi.xenia-pos.net/images/' + item + '.png'"
              @click="set_lang(item)"
              style="width: 30px; cursor: pointer"
            />
          </span>
          <b-nav-item
            v-if="total > 0"
            to="/cart"
            class="d-none d-md-block d-sm-block d-lg-block d-xl-none"
          >
            <CartWidget />
          </b-nav-item>
        </b-nav-form>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import CartWidget from "@/components/shopping-cart/CartWidget";

export default {
  name: "app-header",
  data() {
    return {
      min_orderValue: 0,
    };
  },
  computed: {
    ...mapGetters(["loggedIn", "companyData", "total"]),
    logoUrl() {
      return this.companyData.companyName;
    },
  },
  components: {
    CartWidget,
  },
  created() {
    this.min_orderValue = this.companyData.minimumOrderValue;
  },
  methods: {
    get_lang() {
      return Vue.i18n.locale();
    },
    set_lang(value) {
      Vue.i18n.set(value);
    },
  },
};
</script>

<style scoped>
.min_orderValue {
  color: red;
}
.dropdown-item img {
  width: 50px;
  padding: 5px;
}
.submenu {
  position: relative;
  margin-left: 100%;
}
.icon-wrapper {
  color: #ffffff;
}
</style>
