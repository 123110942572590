<template>
  <div>
    <div class="panel t1 p-1 noselect" style="margin-bottom: 70px">
      <div v-if="product.promotion" class="ribbon ribbon-bottom-left">
        <span>{{ "ANGEBOT" | translate }}</span>
      </div>
      <div class="row">
        <div class="col-lg-5">
          <div class="panel t1 p-1 my-2">
            <div class="d-md-none d-lg-block">
              <div>
                <h3 v-if="langIndex == 1 && product.name1">
                  {{ product.name1 }}
                </h3>
                <h3 v-else-if="langIndex == 2 && product.name2">
                  {{ product.name2 }}
                </h3>
                <h3 v-else-if="langIndex == 3 && product.name3">
                  {{ product.name3 }}
                </h3>
                <h3 v-else>{{ product.name }}</h3>
              </div>
              <div class="mb-2">
                <div class="text-center m-0">
                  <img
                    v-bind:src="product.imageUrl"
                    @error="replaceByDefault"
                    class="product_image rounded"
                  />
                </div>
              </div>
              <div class="text-left">
                <p
                  v-if="product.productInfoTransLated"
                  style="white-space: pre-line"
                >
                  {{ translate(product.productInfoTransLated) }}
                </p>
              </div>
            </div>
            <div class="d-none d-md-block d-lg-none">
              <div>
                <h3 v-if="langIndex == 1 && product.name1">
                  {{ product.name1 }}
                </h3>
                <h3 v-else-if="langIndex == 2 && product.name2">
                  {{ product.name2 }}
                </h3>
                <h3 v-else-if="langIndex == 3 && product.name3">
                  {{ product.name3 }}
                </h3>
                <h3 v-else>{{ product.name }}</h3>
              </div>
              <table width="100%">
                <tr>
                  <td width="30%">
                    <div class="mb-2">
                      <div class="text-center m-0">
                        <img
                          v-bind:src="product.imageUrl"
                          @error="replaceByDefault"
                          style="width: 100%"
                          class="rounded"
                        />
                      </div>
                    </div>
                  </td>
                  <td width="70%" style="padding-left: 15px">
                    <div class="text-left">
                      <p
                        v-if="product.productInfoTransLated"
                        style="white-space: pre-line"
                      >
                        {{ translate(product.productInfoTransLated) }}
                      </p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>

            <div class="row">
              <div class="col-lg-7">
                <div v-if="availCourses.length">
                  <b-form-select v-model="course" class="mb-3">
                    <b-form-select-option :value="null">{{
                      "Menüfolge" | translate
                    }}</b-form-select-option>
                    <b-form-select-option
                      v-for="(item, index) in availCourses"
                      :key="index"
                      :value="item"
                      >{{ item }}</b-form-select-option
                    >
                  </b-form-select>
                </div>
              </div>
              <div class="col-lg-5">
                <div class="d-md-block">
                  <div
                    class="option-button p-0 ml-1"
                    v-on:click="
                      productQuantity > 1 ? (productQuantity -= 1) : ''
                    "
                  >
                    <BaseIcon name="minus" class="m-auto" />
                  </div>
                  <b-form-input
                    class="option-quantity p-0 ml-1 noselect"
                    size="lg"
                    v-model="productQuantity"
                    disabled
                  ></b-form-input>
                  <div
                    class="option-button p-0 ml-1"
                    v-on:click="
                      productQuantity < 10 ? (productQuantity += 1) : ''
                    "
                  >
                    <BaseIcon name="plus" class="m-auto" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="ageCheck">
              <div class="ageBadge">
                <img
                  v-bind:src="'/assets/img/' + minAge + 'plus.png'"
                  style="width: 36px; cursor: pointer; z-index: 999"
                  @click="toggleModal()"
                />
              </div>

              <b-modal
                ref="ageCheckModal"
                id="ageCheckModal"
                class="modal fade"
                centered
                role="dialog"
                hide-footer
                hide-header
              >
                <b-container fluid>
                  <div :style="matchStyle">
                    <div class="row">
                      <div class="col-lg-12">
                        <b class="text-danger"
                          >{{ "MindestalterModalB" | translate }} {{ minAge }}
                          {{ "MindestalterModalA" | translate }}</b
                        >
                      </div>
                      <div class="col-lg-12">
                        <button
                          class="btn btn-md btn-primary mx-2 mt-3"
                          @click="accept(minAge)"
                        >
                          {{ "Bestätigen" | translate }}
                        </button>
                        <button
                          class="btn btn-md btn-secondary mx-2 mt-3"
                          @click="deny()"
                        >
                          {{ "Abbrechen" | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </b-container>
              </b-modal>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div v-if="productOptions.length" class="panel t1 p-1 mt-2">
            <div
              v-for="(prodOpt, parent_index) in productOptions"
              :key="parent_index"
            >
              <h5 class="my-1 text-center">
                {{ translate(prodOpt.nameTranslated) }}
              </h5>
              <div v-for="(subOpt, index) in prodOpt.options" :key="index">
                <div
                  class="check-button-unchecked"
                  :ref="'subOptionBtn' + subOpt.id"
                  @click="PlusOne(prodOpt.multiselect, parent_index, index)"
                >
                  <span class="cbb_title">
                    <span v-if="langIndex == 1 && subOpt.name1">
                      {{ subOpt.name1 }}
                    </span>
                    <span v-else-if="langIndex == 2 && subOpt.name2">
                      {{ subOpt.name2 }}
                    </span>
                    <span v-else-if="langIndex == 3 && subOpt.name3">
                      {{ subOpt.name3 }}
                    </span>
                    <span v-else>
                      {{ subOpt.name }}
                    </span>
                  </span>
                  <div v-if="subOpt.addPrice > 0" class="cbb_price">
                    ({{ subOpt.addPrice | currency(cSymb) }})
                  </div>
                </div>

                <b-form-input
                  :name="subOpt.name"
                  :ref="'subOption' + subOpt.id"
                  v-model="subOpt.optionQuantity"
                  hidden
                ></b-form-input>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div v-if="allowNotes" class="panel t1 p-2 mt-2">
            <h4>{{ "Anmerkung" | translate }}:</h4>
            <b-form-textarea v-model="productNote" class="mb-3" />
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-show="buttonRow" class="cbb">
        <div class="cbb_wrapper">
          <div class="footerAfter"></div>
          <h2 class="cbb_total m-0 p-0">
            <strong>{{ totalPrice | currency(cSymb) }}</strong>
          </h2>
          <b-button-group>
            <b-button variant="secondary" @click="goBack()">
              {{ "Abbrechen" | translate }}
            </b-button>
            <b-button
              v-if="!product.soldOut"
              variant="primary"
              :disabled="isActive"
              @click="
                addToCart(cartItem);
                goBack();
              "
            >
              {{ "Bestellen" | translate }}
            </b-button>
            <b-button v-else variant="secondary" disabled>
              {{ "Ausverkauft" | translate }}
            </b-button>
          </b-button-group>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DataService from "@/services/DataService";
import Vue from "vue";
export default {
  name: "app-singleproduct",
  props: {
    product: Object,
  },
  data() {
    return {
      productOptions: Object,
      productQuantity: 1,
      productNote: "",
      buttonRow: false,
      isActive: true,
      course: null,
    };
  },
  created: function () {
    var creOpt = this.product.optionGroups;
    this.productOptions = [];
    if (creOpt) {
      for (var i = 0; i < creOpt.length; i++) {
        this.productOptions.push({
          id: creOpt[i].id,
          name: creOpt[i].name,
          nameTranslated: creOpt[i].nameTranslated,
          multiselect: creOpt[i].multiselect,
          options: [],
        });
        for (var s = 0; s < creOpt[i].options.length; s++) {
          this.productOptions[i].options.push({
            id: creOpt[i].options[s].id,
            name: creOpt[i].options[s].name,
            name1: creOpt[i].options[s].name1,
            name2: creOpt[i].options[s].name2,
            name3: creOpt[i].options[s].name3,
            addPrice: creOpt[i].options[s].addPrice,
            optionQuantity: 0,
          });
        }
      }
    }
  },
  computed: {
    ...mapGetters(["companyData"]),
    allowNotes() {
      return this.companyData.allowNotes;
    },
    minAge() {
      if (this.product.ageCheck == 1) {
        return 16;
      } else if (this.product.ageCheck == 2) {
        return 18;
      } else {
        return 0;
      }
    },
    availCourses() {
      return this.companyData.courses;
    },
    ageCheck() {
      var minAge = this.product.ageCheck;
      if (minAge != 0) {
        return true;
      } else {
        return false;
      }
    },
    firstRun16() {
      return this.$firstRun16;
    },
    firstRun18() {
      return this.$firstRun18;
    },
    cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
    langIndex() {
      var lang = Vue.i18n.locale();
      var index = this.companyData.languages.findIndex((x) => x === lang);
      return index;
    },
    selectedOptions() {
      var selOpt = this.productOptions;
      var boston = new Array();
      for (var i = 0; i < selOpt.length; i++) {
        boston.push({
          id: selOpt[i].id,
          name: selOpt[i].name,
          nameTranslated: selOpt[i].nameTranslated,
          multiselect: selOpt[i].multiselect,
          options: [],
        });
        for (var s = 0; s < selOpt[i].options.length; s++) {
          if (selOpt[i].options[s].optionQuantity > 0) {
            boston[i].options.push({
              id: selOpt[i].options[s].id,
              name: selOpt[i].options[s].name,
              name1: selOpt[i].options[s].name1,
              name2: selOpt[i].options[s].name2,
              name3: selOpt[i].options[s].name3,
              addPrice: selOpt[i].options[s].addPrice,
              optionQuantity: this.productQuantity,
            });
          }
        }
      }
      boston = boston.filter((item) => item.options.length > 0);
      return boston;
    },
    totalPrice() {
      var sum = 0;
      var pOpt = this.productOptions;
      for (var i = 0; i < pOpt.length; i++) {
        for (var s = 0; s < pOpt[i].options.length; s++) {
          sum +=
            pOpt[i].options[s].optionQuantity * pOpt[i].options[s].addPrice;
        }
      }
      var total = (this.product.price + sum) * this.productQuantity;
      return total;
    },
    cartItem() {
      var cartItem = new Object();
      cartItem["nr"] = this.makeId;
      cartItem["id"] = this.product.id;
      cartItem["selectedOptions"] = this.selectedOptions;
      cartItem["productQuantity"] = this.productQuantity;
      cartItem["totalPrice"] = this.totalPrice;
      cartItem["productNote"] = this.productNote;
      cartItem["productGroup"] = this.product.groupId;
      cartItem["productCourse"] = this.course;
      cartItem["productShift"] = this.product.validForShift;
      cartItem["productDate"] = this.product.validFor;
      return cartItem;
    },
    matchStyle() {
      var element = document.getElementById("app");
      var style = element.getAttribute("style");
      return style;
    },
  },
  methods: {
    ...mapActions(["addToCart"]),
    makeId(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    productInfo(val) {
      var lang = Vue.i18n.locale();
      var index = this.companyData.languages.findIndex((x) => x === lang);
      var info = val.split("|");
      return info[index];
    },
    translate(val) {
      var lang = Vue.i18n.locale();
      var i = this.companyData.languages.findIndex((x) => x === lang);
      var info = val.split("|");
      var infotext = info[i];
      if (val.indexOf("|") > 0) {
        return infotext;
      } else {
        return val;
      }
    },
    GetArticleImageUrl(id) {
      return DataService.getArticleImageUrl(id);
    },
    PlusOne(mslct, parent_index, index) {
      var id = this.productOptions[parent_index].options[index]["id"];
      var options = this.productOptions[parent_index].options;
      var el = "subOption" + id;
      var btn = "subOptionBtn" + id;
      var orderState = this.$refs[el][0].$refs.input.value;
      if (orderState == 0) {
        this.productOptions[parent_index].options[index]["optionQuantity"] = 1;
        this.$refs[btn][0].classList.add("check-button-checked");
        if (mslct == false) {
          for (var i = 0; i < options.length; i++) {
            var bli = "subOptionBtn" + options[i].id;
            if (options[i].id !== id) {
              this.productOptions[parent_index].options[i][
                "optionQuantity"
              ] = 0;
              this.$refs[bli][0].classList.remove("check-button-checked");
            }
          }
        }
      } else if (orderState == 1 && mslct == true) {
        this.productOptions[parent_index].options[index]["optionQuantity"] = 0;
        this.$refs[btn][0].classList.remove("check-button-checked");
      }
    },
    goBack() {
      setTimeout(() => {
        this.$router.push({
          name: "order",
          params: { grpNme: this.product.groupName },
        });
      }, 300);
    },
    replaceByDefault(e) {
      e.target.src = "/assets/img/no_image.png";
    },
    showModal() {
      this.$refs["ageCheckModal"].show();
    },
    accept(age) {
      if (age == 18) {
        Vue.prototype.$firstRun16 = false;
        Vue.prototype.$firstRun18 = false;
      } else if (age == 16) {
        Vue.prototype.$firstRun16 = false;
        Vue.prototype.$firstRun18 = true;
      }
      this.isActive = false;
      this.$refs["ageCheckModal"].hide();
    },
    deny() {
      Vue.prototype.$firstRun16 = true;
      Vue.prototype.$firstRun18 = true;
      this.$refs["ageCheckModal"].hide();
      this.isActive = true;
    },
    toggleModal() {
      this.$refs["ageCheckModal"].toggle();
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.buttonRow = true;
        if (this.ageCheck) {
          if (this.minAge == 18 && this.$firstRun18) {
            this.isActive = true;
            this.showModal();
          } else if (this.minAge == 16 && this.$firstRun16) {
            this.isActive = true;
            this.showModal();
          } else {
            this.isActive = false;
          }
        } else {
          this.isActive = false;
        }
      }, 300);
    });
    if (this.productOptions.length) {
      for (var i = 0; i < this.productOptions.length; i++) {
        var id = this.productOptions[i].options[0]["id"];
        var mslct = this.productOptions[i].multiselect;
        var btn = "subOptionBtn" + id;
        if (mslct == false) {
          this.productOptions[i].options[0]["optionQuantity"] = 1;
          this.$refs[btn][0].classList.add("check-button-checked");
        } else {
          for (var s = 0; s < this.productOptions[i].options.length; s++) {
            this.productOptions[i].options[s]["optionQuantity"] = 0;
          }
        }
      }
    }
  },
};
</script>

<style scoped>
.product_image {
  max-width: 250px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
