<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="cart panel t1 p-2">
        <ShoppingCartSummary class="mb-2" />

        <div v-if="items.length">
          <div class="panel t1 px-1 pt-1 mb-2">
            <ShoppingCartItem
              v-for="sub in items"
              :cartItem="sub"
              :key="sub.index"
            />
          </div>
          <div class="panel t1 px-1 pt-1 mb-2">
            <h5>{{ "Anmerkung" | translate }}:</h5>
            <b-form-textarea v-model="comment" class="mb-3" />
          </div>

          <div class="d-flex justify-content-between mb-2">
            <div class="m-0">
              <button
                class="
                  btn btn-md btn-secondary
                  m-0
                  d-none d-md-block d-sm-block d-lg-none
                "
                @click="goBack()"
              >
                {{ "Abbrechen" | translate }}
              </button>
            </div>
            <div class="m-0 p-0">
              <button class="btn btn-md btn-secondary m-0" @click="emptyCart">
                {{ "Leeren" | translate }}
              </button>
            </div>
          </div>

          <button
            class="btn btn-lg btn-primary btn-block m-0"
            @click.prevent="payCash()"
          >
            {{ "Bestellen" | translate }}
          </button>
        </div>
        <b-modal
          id="checkoutModal"
          ref="checkoutModal"
          size="lg"
          hide-backdrop
          hide-footer
          hide-header
          :body-bg-variant="bodyBgVariant"
          :body-text-variant="bodyTextVariant"
        >
          <div class="text-center m-0 p-0">
            {{ "Betrag" | translate }}:
            {{ totalAmount | currency(cSymb) }}
            <div id="pwloader"></div>
            {{ "Ihre Bestellung wird verarbeitet!" | translate }}
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import ShoppingCartItem from "@/components/shopping-cart/ShoppingCartItem";
import ShoppingCartSummary from "@/components/shopping-cart/ShoppingCartSummary";
import DataService from "@/services/DataService";
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";

export default {
  name: "app-cart",
  computed: {
    ...mapGetters({ items: "cartProducts" }),
    ...mapGetters(["total", "companyData", "userData", "clientData"]),
    cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
    shopCart() {
      var scart = [];
      var self = this;
      scart.push({
        payMethod: 0,
        transactionReference: "",
        RoomNumber: "",
        addressId: 0,
        deliveryMinutes: 0,
        comment: "",
        total: 0,
        tableNumber: "0",
        posList: this.items.map((item) => {
          item = {
            articleId: item.id,
            quantity: item.productQuantity,
            singlePrice: item.price,
            variantList: item.selectedOptions
              .map((opt) => opt.options.map((x) => x.id))
              .join(),
            parent: true,
            name: item.name,
            note: item.productNote,
            course: self.findIndexCourse(item.productCourse),
          };
          return item;
        }),
      });
      for (var i = 0; i < this.items.length; i++) {
        for (var s = 0; s < this.items[i].selectedOptions.length; s++) {
          for (
            var m = 0;
            m < this.items[i].selectedOptions[s].options.length;
            m++
          ) {
            scart[0].posList.push({
              articleId: this.items[i].selectedOptions[s].options[m].id,
              quantity:
                this.items[i].selectedOptions[s].options[m].optionQuantity,
              singlePrice: this.items[i].selectedOptions[s].options[m].addPrice,
              variantList: this.items[i].id.toString(),
              parent: false,
              name: this.items[i].selectedOptions[s].options[m].name,
            });
          }
        }
      }
      scart = scart.map(function (e) {
        return JSON.stringify(e);
      });
      scart = scart.join(",");
      return scart;
    },
    userID() {
      return this.userData.loginName;
    },
    clientID() {
      return this.clientData.clientID["id"];
    },
    clientName(){
      return this.clientData.clientID["name"];
    },
    clientRoom() {
      return this.clientData.clientID["room"];
    },
    clientSubRoom(){
      if(this.clientData.clientID["subRoom"]){
        return this.clientData.clientID["room"] + "/" + this.clientData.clientID["subRoom"];
      }else{
        return this.clientData.clientID["room"];
      }
    },
    clientInfo(){
      return this.clientData.clientID["info"];
    },
    PreOrderDate() {
      return this.clientData.validFor;
    },
    shift() {
      return this.clientData.validForShift;
    },
    totalAmount() {
      return this.total.toFixed(2);
    },
  },
  components: {
    ShoppingCartItem,
    ShoppingCartSummary,
  },
  data() {
    return {
      min_orderValue: 0,
      paymentSubmit: false,
      spinner: false,
      cancellation: true,
      payType: "",
      paymentMethod: 7,
      transId: "",
      guestName: "",
      roomNumber: "",
      deliveryMinutes: 0,
      comment: "",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
    };
  },
  created() {
    this.getProducts({ key: this.companyData.menuIdPreOrder });
    this.min_orderValue = this.companyData.minimumOrderValue;
  },
  methods: {
    ...mapActions(["getProducts", "removeAllFromCart"]),
    payCash() {
      this.$refs["checkoutModal"].show();
      this.payType = "Barzahlung";
      this.paymentMethod = 5;
      this.sendOrder((this.transId = "000"));
    },
    sendOrder(transId) {
      var cart_content = JSON.parse(this.shopCart);
      cart_content.loginName = this.userID;
      cart_content.payMethod = this.paymentMethod;
      cart_content.ClientName = this.clientName +" "+ this.clientSubRoom;
      cart_content.info = this.clientInfo;
      cart_content.transactionReference = transId;
      cart_content.RoomNumber = this.clientRoom;
      cart_content.addressId = -1;
      cart_content.deliveryMinutes = this.deliveryMinutes;
      cart_content.comment = this.comment;
      cart_content.total = parseFloat(this.total);
      cart_content.tableNumber = "0";
      cart_content.menuId = 0;
      cart_content.pickUp = false;
      cart_content.isPreorder = true;
      cart_content.reservationId = this.clientID;
      cart_content.PreOrderDate = this.PreOrderDate;
      cart_content.Shift = this.shift;
      DataService.createOrder(cart_content, this.userData.token);
      setTimeout(() => {
        this.removeAllFromCart();
        this.$refs["checkoutModal"].hide();
        this.$root.$emit("selectNewClient");
      }, 3000);
    },
    emptyCart() {
      Vue.prototype.$firstRun16 = true;
      Vue.prototype.$firstRun18 = true;
      this.removeAllFromCart();
      this.goBack();
    },
    goBack() {
      if (this.$route.matched.some(({ name }) => name === "order")) {
        null;
      } else {
        setTimeout(() => {
          this.$router.push({ name: "order" });
        }, 300);
      }
    },
    checkOut() {
      setTimeout(() => {
        this.$router.push({ name: "CheckOut" });
      }, 300);
    },
    findIndexCourse(name) {
      var courses = this.companyData.courses;
      if (name != null) {
        var index = courses.indexOf("" + name + "");
        return index + 1;
      } else {
        return 0;
      }
    },
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.$root.$on("remoteCheckout", () => {
      this.payCash();
    });
  },
  watch: {
    total(val) {
      if (val == 0) {
        if (this.$route.path === "/cart") this.$router.push({ path: "/" });
      }
    },
  },
};
</script>
<style scoped>
.min_orderValue {
  color: red;
  font-size: 20px;
}
</style>
