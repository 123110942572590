import Vue from "vue";
import Router from "vue-router";
import Order from "./views/Order.vue";
import Product from "./views/ProductPage.vue";
import Cart from "./views/CartPage.vue";
import LoginUser from "./views/Login.vue";
import Orders from "./views/Orders.vue";
import PosList from "./views/PosList.vue";
import v27gCDJx0G6r from "./views/v27gCDJx0G6r.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "order",
      component: Order,
      props: true,
      beforeEnter(to, from, next) {
        if (to.query.reset) {
          window.localStorage.clear();
          next({ name: "login" });
        } else {
          next();
        }
      },
    },
    {
      path: "/product/:productId",
      name: "productPage",
      component: Product,
      meta: {
        requiresAuth: true,
        hideClientButton: true,
      },
      props: true,
    },
    {
      path: "/cart",
      name: "CartPage",
      component: Cart,
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/login",
      name: "login",
      component: LoginUser,
      meta: {
        hideClientHeader: true,
      },
    },
    {
      path: "/orders",
      name: "orders",
      component: Orders,
      meta: {
        requiresAuth: true,
        hideClientHeader: true,
      },
    },
    {
      path: "/poslist/:orderId",
      name: "posList",
      component: PosList,
      meta: {
        requiresAuth: true,
        hideClientHeader: true,
      },
      props: true,
    },
    {
      path: "/v27gCDJx0G6r",
      name: "v27gCDJx0G6r",
      component: v27gCDJx0G6r,
    },
  ],
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => { });
};

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
