import axios from "axios";

const apiClient = axios.create({
  //baseURL: "https://selfapi.xenia-pos.net/api/",
  //baseURL: "https://apipreorder.xenia-pos.net/api/",
  baseURL: "https://apiv1.xenia-pos.net/api/",
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  }
});


export default {
  getStore(key,token) {
    apiClient.defaults.headers.common["x-api-key"] = key;
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return apiClient.get("/Setup");
  },

  getAllProducts(key) {
    return apiClient.get("/Article/" + key);
  },

  allByMatch(data, token, key) {
    apiClient.defaults.headers.common["x-api-key"] = key;
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return apiClient.get("/HotelReservation/ByMatch?match=" + data);
  },

  createOrder(cart_content, token) {
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return apiClient.post("/CreateOrder", cart_content);
  },

  getUser(key, token) {
    apiClient.defaults.headers.common["x-api-key"] = key;
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return apiClient.get("/User");
  },

  getOrders(key, token) {
    apiClient.defaults.headers.common["x-api-key"] = key;
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return apiClient.get("/Orders");
  },

  userLogin(key, credentials) {
    apiClient.defaults.headers.common["x-api-key"] = key;
    return apiClient.post("/Login", credentials);
  },

};
